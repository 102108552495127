<template>
  <div class="pro-main">
    <v-data-table
      :headers="headers"
      :items="projects"
      sort-by="project_code"
      class="elevation-1"
    >
    <template v-slot:item.project_coordinator="{ item }">
        <div>{{ getUserName(item.project_coordinator) }}</div>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title><h3 class="primary--text">Project Details</h3></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
               <v-icon>mdi-plus</v-icon> New
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h6 primary--text">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text class="mt-5 pb-0">
                <v-container>
                  <v-row>
                    <v-col cols="12"  md="6" class="py-0 my-0">
                      <v-text-field
                        v-model="editedItem.project_code"
                        label="Project code"
                        clearable
                        filled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12"  md="6" class="py-0 my-0">
                      <v-select
                        v-model="editedItem.project_coordinator"
                        :items="cItems"
                        label="Project Coordinator"
                        :item-text="item =>`${item.first_name} ${item.last_name}`"
                        item-value="user_id"
                        filled
                        clearable
                      ></v-select>
                    </v-col>
                    <v-col cols="12" class="py-0 my-0">
                      <v-text-field
                        v-model="editedItem.description"
                        label="Description"
                        clearable
                        filled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close">
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  @click="
                    save();
                    snackbar = true;
                  "
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      </template>
    </v-data-table>
    <v-btn class="mb-3 ml-3 mt-3">
      <export-excel :data="projects" :name="excel_name" :fields="excel_headders">
        Download Excel
      </export-excel>
    </v-btn>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
const axios = require("axios").default;

export default {
  data: () => ({
    excel_name:
      "Projects" +
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10) +
      ".xls",
    dialog: false,
    headers: [
      {
        text: "Project Code",
        align: "start",
        sortable: false,
        value: "project_code",
      },
      { text: "Project Coordinator", value: "project_coordinator" },
      { text: "Description", value: "description" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    excel_headders: {
        "Project Code": "project_code",
        "Project Coordinator": "project_coordinator" ,
        "Description": "description" ,
      },
    projects: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      project_code: "",
      project_coordinator: "",
      description: "",
    },
    defaultItem: {
      project_code: "",
      project_coordinator: "",
      description: "",
    },
    cItems: "",
    snackbar: false,
    text: ``,
    timeout: 4000,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    getUserName(user_id) {
      let coordinator =
        this.cItems[
          this.cItems.findIndex((element) => element.user_id == user_id)
        ];
      if (coordinator) {
        return coordinator.first_name + ' ' + coordinator.last_name;
      } else {
        return '-';
      }
    },
    editItem(item) {
      this.editedIndex = this.projects.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    readDataFromAPI() {
      axios.get(this.$backend_url+"projects").then((response) => {
        this.projects = response.data.projects;
      });
    },

    readUsersDataFromAPI() {
      axios
        .get(this.$backend_url+"project-coordinators")
        .then((response) => {
          if (response.data.success) {
            this.cItems = response.data.projects;
          }
        })
        .catch((res) => {
          console.log("Errors:", res);
        });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.projects[this.editedIndex], this.editedItem);
        axios
          .put(this.$backend_url+"projects", {
            id: this.editedItem.id,
            project_code: this.editedItem.project_code,
            project_coordinator: this.editedItem.project_coordinator,
            description: this.editedItem.description,
          })
          .then((res) => {
            if (res.data.success === true) {
              this.text = `Project has been successfully modified.`;
            }
            if (res.data.success === false) {
              this.text = `Project already exists!!!`;
            }
          })
          .catch((res) => {
            console.log("Errors:", res);
            this.text = `Error occured while sending data.`;
            window.location.reload();
          });
      } else {
        axios
          .post(this.$backend_url+"projects", {
            project_code: this.editedItem.project_code,
            project_coordinator: this.editedItem.project_coordinator,
            description: this.editedItem.description,
          })
          .then((res) => {
            if (res.data.success === true) {
              this.text = `Project has been successfully added.`;
              this.projects.push(this.editedItem);
              window.location.reload();
            }
            if (res.data.success === false) {
              this.text = `Project already exists!`;
            }
          })
          .catch((res) => {
            console.log("Errors:", res);
            this.text = `Error occured while sending data.`;
          });
      }
      this.close();
    },
  },

  mounted() {
    if(! (JSON.parse(localStorage.imso_user).role == 1)){
      this.$router.push("/");
    }
    this.readDataFromAPI();
    this.readUsersDataFromAPI();
  },
};
</script>

<style>
.pro-main {
  padding: 20px;
  margin-left: 50px;
  width: calc(100vw - 60px);
  min-height: calc(100vh - 200px);
}
@media only screen and (max-width: 960px) {
  .pro-main {
    padding-right: 0px;
  }
}
@media only screen and (max-width: 600px) {
  .pro-main {
    padding: 20px;
    margin-left: 0px;
    width: 100vw;
  }
}
</style>