var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pro-main"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.projects,"sort-by":"project_code"},scopedSlots:_vm._u([{key:"item.project_coordinator",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.getUserName(item.project_coordinator)))])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('h3',{staticClass:"primary--text"},[_vm._v("Project Details")])]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" New ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h6 primary--text"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',{staticClass:"mt-5 pb-0"},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Project code","clearable":"","filled":""},model:{value:(_vm.editedItem.project_code),callback:function ($$v) {_vm.$set(_vm.editedItem, "project_code", $$v)},expression:"editedItem.project_code"}})],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.cItems,"label":"Project Coordinator","item-text":function (item) { return ((item.first_name) + " " + (item.last_name)); },"item-value":"user_id","filled":"","clearable":""},model:{value:(_vm.editedItem.project_coordinator),callback:function ($$v) {_vm.$set(_vm.editedItem, "project_coordinator", $$v)},expression:"editedItem.project_coordinator"}})],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Description","clearable":"","filled":""},model:{value:(_vm.editedItem.description),callback:function ($$v) {_vm.$set(_vm.editedItem, "description", $$v)},expression:"editedItem.description"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.save();
                  _vm.snackbar = true;}}},[_vm._v(" Save ")])],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                  var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}}])}),_c('v-btn',{staticClass:"mb-3 ml-3 mt-3"},[_c('export-excel',{attrs:{"data":_vm.projects,"name":_vm.excel_name,"fields":_vm.excel_headders}},[_vm._v(" Download Excel ")])],1),_c('v-snackbar',{attrs:{"timeout":_vm.timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }